<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <simple-table
            table-title="Provinces"
            create-new-item-label="Add Province"
            :headers="provinces.headers"
            :items="provinces.items"
            :enabled-action-deactivate="true"
            :enabled-action-delete="false"
            @createNewItem="createNewProvince"
            @editItem="editProvince"
            @updateItemStatus="updateItemStatus"
          ></simple-table>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12" class="mt-n3">
          <simple-table
            table-title="Address Types"
            create-new-item-label="Add Address Type"
            :headers="addressTypes.headers"
            :items="addressTypes.items"
            :enabled-action-deactivate="true"
            :enabled-action-delete="false"
            @createNewItem="createNewAddressType"
            @editItem="editAddressType"
            @updateItemStatus="updateItemStatus"
          ></simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-n3">
          <simple-table
            table-title="Contact Types"
            create-new-item-label="Add Contact Type"
            :headers="contactTypes.headers"
            :items="contactTypes.items"
            :enabled-action-deactivate="true"
            :enabled-action-delete="false"
            @createNewItem="createNewContactType"
            @editItem="editContactType"
            @updateItemStatus="updateItemStatus"
          ></simple-table>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import SimpleTable from "@/components/Tables/SimpleTable.vue";
export default {
  name: "settings",
  components: {
    HeaderTopDashboard,
    SimpleTable,
  },
  data() {
    return {
      addressTypes: {
        headers: [
          { text: "Name", value: "name" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        items: [],
      },
      contactTypes: {
        headers: [
          { text: "Name", value: "name" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        items: [],
      },
      provinces: {
        headers: [
          { text: "Name", value: "name" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        items: [],
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewAddressType() {
      this.$router.push("/settings/address-types/create");
    },

    createNewContactType() {
      this.$router.push("/settings/contact-types/create");
    },

    createNewProvince() {
      this.$router.push("/settings/provinces/create");
    },

    editAddressType(item) {
      this.$router.push(`/settings/address-types/edit/${item.id}`);
    },

    editContactType(item) {
      this.$router.push(`/settings/contact-types/edit/${item.id}`);
    },

    editProvince(item) {
      this.$router.push(`/settings/provinces/edit/${item.id}`);
    },

    reloadStaticData() {
      this.fetchStaticDataByGrouping("addressTypes")
        .then((result) => {
          this.addressTypes.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            this.addressTypes.items.push({
              id: result.data.data[i].id,
              name: result.data.data[i].value,
              status: result.data.data[i].status,
            });
          }
        })
        .catch((error) => {
          console.error("Error while loading address types: ", error);
        });

      this.fetchStaticDataByGrouping("contactTypes")
        .then((result) => {
          this.contactTypes.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            this.contactTypes.items.push({
              id: result.data.data[i].id,
              name: result.data.data[i].value,
              status: result.data.data[i].status,
            });
          }
        })
        .catch((error) => {
          console.error("Error while loading contact types: ", error);
        });

      this.fetchStaticDataByGrouping("provinces")
        .then((result) => {
          this.provinces.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            this.provinces.items.push({
              id: result.data.data[i].id,
              name: result.data.data[i].value,
              status: result.data.data[i].status,
            });
          }
        })
        .catch((error) => {
          console.error("Error while loading provinces: ", error);
        });
    },

    updateItemStatus(item, status) {
      const request = {
        status,
      };

      this.$http
        .patch(
          `/static-data/${item.id}`,
          request,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.reloadStaticData();
          this.$notify.success("Setting successfully updated!");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the setting. Please try again later or contact support."
          );
          console.error("Error while updating the setting: ", error);
        });
    },
  },
  mounted() {
    this.reloadStaticData();
  },
};
</script>
